import React from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa'
import { below, breakpointIntegers } from '../styles/abstracts/breakpoints.js'

const Root = styled.div`
  font-family: Source Sans Pro;
  color: #333333;
  background-color: ${props => props.theme.color.white};
`
const InnerContainer = styled.div`
  width: 55%;
  margin: auto;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  ${below.xl} {
    width: 70%;
  }
  ${below.lg} {
    width: 80%;
  }
  ${below.md} {
    width: 90%;
  }
`
const QuestionContainer = styled.div``
const Question = styled.h2`
  font-size: 38px;
  margin-top: 31px;
  margin-bottom: 34px;
  font-weight: bold;
  cursor: default;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`
const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`
const Answer = styled.div`
  margin-bottom: 34px;
  font-size: 32px;
  margin-left: 3px;
  animation-name: ${fadeIn};
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
  cursor: default;
  ${below.md} {
    font-size: 18px;
    line-height: 30px;
  }
`
const QuestionBorder = styled.div`
  opacity: 0.3;
  border: 0.5px solid #979797;
`
const ExpandIcon = styled(FaPlusSquare)`
  float: right;
`
const ContractIcon = styled(FaMinusSquare)`
  float: right;
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const AnswerButton = styled.div`
  margin-top: 30px;
  background-color: #ffbc00;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 187, 0, 0.89);
    transition: 0.3s;
  }
`
const ButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.color.black};
`
const QuestionWrapper = styled.div`
  display: flex;
`
const QuestionText = styled.div`
  width: 90%;
`
const ToggleWrapper = styled.div`
  margin-top: 7px;
`
class FAQ extends React.Component {
  constructor(props) {
    super(props)

    //Setup initial toggle status of each question in the FAQ
    let initialState = {}
    this.props.block.questions.forEach((question, i) => {
      initialState[i] = false
    })

    this.state = initialState
  }

  toggleExpansion = i => {
    this.setState({
      [i]: !this.state[i]
    })
  }

  render() {
    const faqList = this.props.block.questions.map((questionObj, i) => {
      let toggleIcon = <ExpandIcon />
      let answer = ''
      let answerButton = ''
      if (questionObj.buttonText && questionObj.buttonUrl) {
        answerButton = (
          <StyledLink to={questionObj.buttonUrl}>
            <AnswerButton>
              <ButtonText>{questionObj.buttonText}</ButtonText>
            </AnswerButton>
          </StyledLink>
        )
      }
      if (this.state[i]) {
        toggleIcon = <ContractIcon />
        answer = (
          <Answer>
            {questionObj.answer}
            {answerButton}
          </Answer>
        )
      }
      return (
        <QuestionContainer
          key={i}
          onClick={() => {
            this.toggleExpansion(i)
          }}
        >
          <Question>
            <QuestionWrapper>
              <QuestionText>{questionObj.question}</QuestionText>
              <ToggleWrapper>{toggleIcon}</ToggleWrapper>
            </QuestionWrapper>
          </Question>
          {answer}
          <QuestionBorder />
        </QuestionContainer>
      )
    })
    return (
      <Root>
        <InnerContainer>{faqList}</InnerContainer>
      </Root>
    )
  }
}

export default FAQ
